.repots_tab .tab_btn {
  border: 1px solid #1d4354;
  margin-right: 10px;
  color: #1d4354;
}

.repots_tab .tab_btn.active {
  background: #1d4354;
}
.repots_tab .btn-default.active {
  background: #1d4354;
  color:white;
}
.repots_tab .select-group .input-group-addon {
  background: none;
}

.repots_tab .select-group select {
  border-left: 0;
  padding-left: 0;
  box-shadow: none;
}

.repots_tab .download_icon {
  background: #1d4354;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.repots_tab .download_icon:focus,
.repots_tab select:focus {
  box-shadow: none;
}

.repots_tab .download_file {
  /* width: 120px;
  margin-left: auto; */
  display: flex;
  justify-content: end;
}

.report_table {
  margin-top: 25px;
  /*            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/
  background: #ffff;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
}

.report_table td,
.report_table th {
  padding: 10px 22px;
  border-left: 1px solid #c1c1c1;
  text-align: center;
}

.report_table .holiday {
   /* background: #75c590;  */
  background: #00aba9;
}

.report_table .present {
  background: #75c590;
}

.report_table tr th:first-child,
.report_table tr td:first-child {
  border-left: 0;
}

.report_table tr th:last-child,
.report_table tr td:last-child {
  border-right: 0;
}

.report_table tr th {
  border-bottom-color: #c1c1c1 !important;
}

.report_table tbody tr:last-child td {
  border-bottom: 0;
}

.report_table .full_leave {
  background: #faaf19;
}

.report_table .half_leave {
  background: #7ab3e1;
}
.report_table .absent {
  background: #b91515;
}

.define_leaves_color li {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  font-weight: 500;
}

.define_leaves_color li span:before {
  content: "";
  position: absolute;
  left: 11px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #1d4354;
  top: 50%;
  transform: translateY(-50%);
}

.define_leaves_color li span.yellow:before {
  background: #faaf19;
}

.define_leaves_color li span.blue:before {
  background: #7ab3e1;
}

.define_leaves_color li span.green:before {
  background: #6fbb89;
}
.define_leaves_color li span.holidayColor:before {
  background: #00aba9;
}
.define_leaves_color li span.red:before {
  background: #ca0002;
}

.dataTables_wrapper {
  margin-top: 20px;
}

.dataTables_wrapper .report_table {
  margin: 24px 0 !important;
}

.dataTables_wrapper .page-item.active .page-link {
  background-color: #1d4354;
  border-color: #1d4354;
  color: #fff;
}

.report_table_main .dataTables_wrapper .col-sm-12 {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .page-item .page-link {
  color: #1d4354;
}
#monthly{
  table-layout: fixed;
  width: 100%;
  *margin-left: -100px; /*ie7*/
}
.table-outer{
  position: relative;
}
.table-inner {
  overflow-x: scroll;
  overflow-y: visible;
  margin-left: 300px;
}

#monthly td, #monthly th
{vertical-align: top;
  border-top: 1px solid #ccc;
  padding: 10px;
  
}

#monthly tr th:first-child, #monthly tr td:first-child {
  position: absolute;
  margin-left: -300px;
  width: 50px;
  border-left: 1px solid #ccc;
  margin-top: -1px;
}

#monthly tr th:nth-child(2), #monthly tr td:nth-child(2) {
  position: absolute;
  *position: relative;
  margin-left: -250px;
  width: 50px;
  margin-top: -1px;

}

#monthly tr th:nth-child(3), #monthly tr td:nth-child(3) {
  position: absolute;
  *position: relative;
  margin-left: -200px;
  width: 200px;
  margin-top: -1px;

} 
#monthly tr:last-child td {
  border-bottom: 1px solid #ccc;


} 
.category-repots-tab .tab_btn {
  margin-bottom: 10px;
  white-space: nowrap;
}
#monthly td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .category-repots-tab #pills-tab {
  flex-wrap: nowrap;
  max-width: 1100px;
  overflow-x: scroll;
  scrollbar-width: thin;
} */
/* 
Custom scrollbar style  */
/* ::-webkit-scrollbar {
  height: 6px;              /* height of horizontal scrollbar ← You're missing this 
  width: 6px;               /* width of vertical scrollbar 
  border: 1px solid #d5d5d5;
  background-color: gray;
} 

::-webkit-scrollbar-track {  
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {  
  background: #b0b0b0;
} 
 /* Custom scrollbar style end */
.leaves-report-table #monthly {
  table-layout: auto;
} 

@media screen and (max-width: 991px) {
  .repots_tab .download_file {
    margin-left: 0;
  }

  .define_leaves_color ul {
    margin-left: -11px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: left;
    margin-bottom: 10px;
  }
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: flex-start !important;
  }
}